import { api } from '../utils'
import { DEFAULT_PER_PAGE, ORGANIZATION_TYPE } from '@/constants'

const initState = {
  bpo: {},
  bpos: [],
  count: 0,
  offset: 0,
}

const mutations = {
  setBpos(state, bpos) {
    state.bpos = bpos.data
    state.count = bpos.meta.count
    state.offset = bpos.meta.offset
  },
}

const actions = {
  async fetchBPOs({ commit }, params) {
    if (!params) {
      params = {
        perPage: DEFAULT_PER_PAGE,
      }
    }
    let url = `/api/v1/superadmins/organizations?org_type=${ORGANIZATION_TYPE.BPO}&per_page=${params.perPage}`
    if (params.name) {
      url += `?name=${params.name}`
    }
    if (params.offset) {
      url += `?offset=${params.offset}`
    }
    const res = await api.client.get(url)
    commit('setBpos', res.data)
  },
  async fetchClientBPOs(_, clientId) {
    const res = await api.client.get(`/api/v1/superadmins/organizations/${clientId}/units`)
    return res.data.data || []
  },
  async createBPO(_, bpo) {
    const params = {
      name: bpo.name,
    }
    await api.client.post('/api/v1/superadmins/units', params)
  },
}

export default {
  state: initState,
  mutations,
  actions,
}
