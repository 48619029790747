import { api } from '../utils'

const actions = {
  async attach(_, payload) {
    const params = {
      client_id: payload.client,
      unit_id: payload.bpos,
    }
    await api.client.post('/api/v1/superadmins/attachment', params)
  },
}

export default {
  actions,
}
