import { api } from '../utils'
import { DEFAULT_PER_PAGE, ORGANIZATION_TYPE } from '@/constants'

const initState = {
  client: {},
  clients: [],
  count: 0,
  offset: 0,
}

const mutations = {
  setClients(state, clients) {
    state.clients = clients.data
    state.count = clients.meta.count
    state.offset = clients.meta.offset
  },
}

const actions = {
  async fetchClients({ commit }, params) {
    if (!params) {
      params = {
        perPage: DEFAULT_PER_PAGE,
      }
    }
    let url = `/api/v1/superadmins/organizations?org_type=${ORGANIZATION_TYPE.CLIENT}&per_page=${params.perPage}`
    if (params.name) {
      url += `&name=${params.name}`
    }
    if (params.offset) {
      url += `&offset=${params.offset}`
    }
    const res = await api.client.get(url)
    commit('setClients', res.data)
  },
  async updateClient(_, params) {
    await api.client.put(`/api/v1/superadmins/organizations/${params.id}`, params)
  },
}

export default {
  state: initState,
  mutations,
  actions,
}
