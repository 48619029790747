export default class Storage {
  constructor() {
    this.client = localStorage
  }

  getSession() {
    return this.client.getItem('session_id')
  }

  setSession(token) {
    this.client.setItem('session_id', token)
  }

  removeSession() {
    this.client.removeItem('session_id')
  }
}
