import Vue from 'vue'
import { api, storage } from '../utils'
import { auth } from '../plugins/firebase'

const authState = {
  loginUser: null,
}
const mutations = {
  setSession(state, sessionId) {
    storage.setSession(sessionId)
    api.updateSession(storage)
  },
  removeSession() {
    storage.removeSession()
  },
  setLoginUser(state, payload) {
    state.loginUser = payload
  },
}

const actions = {
  async getCurrentUser({ commit }) {
    if (!auth.currentUser) {
      throw new Error('User is not logged in')
    }
    const tokenResult = await auth.currentUser.getIdTokenResult()
    if (!tokenResult.claims.superadmin) {
      Vue.$snotify.error('Only superadmin can login to this page')
      throw new Error('Only superadmin can login to this page')
    }
    commit('setLoginUser', {
      email: tokenResult.claims.email,
      annotation_user_id: tokenResult.claims.annotation_user_id,
      name: tokenResult.claims.name,
    })
  },
  async getSessionID({ commit }, token) {
    const session = await api.client.post(
      '/auth/',
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    commit('setSession', session.data.session_id)
  },
  async loginWithOpenIDCallback({ dispatch }, query) {
    const res = await api.client.get('/oauth/abeja_platform/oauthcallback', { params: query })
    await auth.signInWithCustomToken(res.data.login_token)
    const token = await auth.currentUser.getIdToken()
    await dispatch('getSessionID', token)
  },
  async logout({ commit }) {
    await api.client.delete('/oauth/')
    commit('removeSession')
    auth.signOut()
    commit('setLoginUser', null)
  },
}

export default {
  namespaced: true,
  state: authState,
  mutations,
  actions,
}
