import { api } from '../utils'

const signupRequestState = {
  requests: [],
}

const mutations = {
  setSignupRequests(state, requests) {
    state.requests = requests
  },
}

const actions = {
  async fetchSignUpRequests({ commit }, accepted) {
    const res = await api.client.get(`/api/v1/superadmins/registrations?accepted=${accepted}`)
    commit('setSignupRequests', res.data)
  },
  async acceptSignUpRequest(_, { requestId }) {
    await api.client.put(`/api/v1/superadmins/registrations/${requestId}`)
  },
}

export default {
  state: signupRequestState,
  mutations,
  actions,
}
