<template>
  <v-app>
    <nav id="nav">
      <Header />
    </nav>
    <v-main>
      <Error :error="error" @onClose="clear" />
      <router-view />
    </v-main>
    <confirm ref="confirm" />
    <vue-snotify />
  </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Header from './components/Header.vue'
import Error from './components/Error.vue'
import Confirm from './components/Confirm.vue'

export default {
  components: {
    Header,
    Error,
    Confirm,
  },
  computed: {
    ...mapState({
      error: (state) => state.error,
    }),
  },
  methods: {
    ...mapActions(['clear']),
  },
  mounted() {
    this.$root.$confirm = this.$refs.confirm.open
  },
}
</script>

<style lang="scss">
@use '~vue-snotify/styles/simple';
</style>
