import { api } from '../utils'

const userState = {
  user: {},
  users: [],
}

const mutations = {
  setUsers(state, users) {
    state.users = users
  },
  setUser(state, user) {
    state.user = user
  },
}

const actions = {
  async fetchUsers({ commit }, { params }) {
    const queryParams = {
      params: {
        ...params,
        per_page: params.perPage,
      },
    }
    const res = await api.client.get('/api/v1/superadmins/users', queryParams)
    commit('setUsers', res.data)
  },
  async updateUser(_, user) {
    const params = {
      is_admin: user.is_admin,
      password: user.password,
    }
    await api.client.put(`/api/v1/superadmins/users/${user.id}`, params)
  },
  async createAnnotationManager(_, payload) {
    const params = {
      email: payload.email,
      role: payload.role,
    }
    await api.client.post(
      `/api/v1/superadmins/organizations/${payload.organizationID}/invitations`,
      params
    )
  },
  async fetchUserDetail({ commit }, userId) {
    const res = await api.client.get(`/api/v1/superadmins/users/${userId}`)
    commit('setUser', res.data)
  },
  async deleteUser(_, userId) {
    await api.client.delete(`/api/v1/superadmins/users/${userId}`)
  },
}

export default {
  state: userState,
  mutations,
  actions,
}
