<template>
  <v-menu open-on-hover bottom offset-y>
    <template v-slot:activator="{ on }">
      <v-btn class="text-capitalize white--text" text v-on="on">
        <v-icon> translate </v-icon>

        <span
          class="subtitle-1 text-capitalize font-weight-light hidden-sm-and-down"
          v-text="currentLanguage.title"
        />
      </v-btn>
    </template>

    <v-list>
      <v-list-item v-for="(item, index) in languages" :key="index" @click="onSelectLanguage(item)">
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  data() {
    return {
      languages: [
        {
          title: '日本語',
          value: 'ja',
        },
        {
          title: 'English',
          value: 'en',
        },
      ],
    }
  },
  computed: {
    currentLanguage() {
      let { locale } = this.$i18n
      locale = locale || 'ja'
      return this.languages.find((l) => l.value === locale)
    },
  },
  methods: {
    onSelectLanguage(item) {
      this.$i18n.locale = item.value
      window.localStorage.setItem('currentLanguage', item.value)
    },
  },
}
</script>
