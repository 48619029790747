import axios from 'axios'

export default class API {
  constructor(storage) {
    this.client = axios.create({
      baseURL: process.env.VUE_APP_BASE_URL,
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    })
    this.client.interceptors.request.use((config) => {
      const newConfig = { ...config }
      const authToken = storage.getSession()
      if (authToken) {
        newConfig.headers = {
          ...config.headers,
          'X-Annotation-Token': authToken,
        }
      }
      return newConfig
    })
    this.client.interceptors.response.use(
      (response) => response,
      (err) => {
        if (err.response.status === 401) {
          window.location = '/login'
        }
        return Promise.reject(err)
      }
    )
    this.client.interceptors.response.use(
      (response) => response,
      (err) => {
        if (this.errorFunc && this.errorFunc instanceof Function) {
          this.errorFunc(err)
        }
        return Promise.reject(err)
      }
    )
  }

  updateSession(storage) {
    this.client.defaults.headers.common['X-Annotation-Token'] = storage.getSession()
  }

  baseURL() {
    return this.client.defaults.baseURL
  }

  onError(errorFunc) {
    this.errorFunc = errorFunc
  }
}
