const initState = {
  message: '',
  code: '',
  status: null,
}

const mutations = {
  setError(state, e) {
    state.message = e.message
    state.status = e.status
    state.code = e.code
  },
  clearError(state) {
    state.message = ''
    state.code = ''
    state.status = null
  },
}

const actions = {
  error({ commit }, e) {
    const err = { status: e.response.status }
    if (e.response.status === 400) {
      err.message = e.response.data.message || '何らかのエラーコード'
      err.code = e.response.data.code || '不明なコード'
    }
    if (e.response.status === 401) {
      err.message = 'ログインが必要です'
      err.code = ''
    }
    if (e.response.status === 403) {
      err.message = '権限がありません'
      err.code = ''
    }
    if (e.response.status === 500) {
      err.message = 'サーバがダウンしています'
      err.code = ''
    }
    return commit('setError', err)
  },
  clear({ commit }) {
    return commit('clearError')
  },
}

export default {
  state: initState,
  mutations,
  actions,
}
