<template>
  <v-snackbar :value="status" :right="true" :bottom="true" :timeout="-1" :vertical="true">
    {{ message }}
    <v-btn color="pink" text @click="clear"> 閉じる </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  computed: {
    message() {
      return this.error.message
    },
    status() {
      return this.error.status
    },
    code() {
      return this.error.code
    },
  },
  watch: {
    status() {
      setTimeout(this.clear, 5000)
    },
  },
  methods: {
    clear() {
      this.$emit('onClose')
    },
  },
}
</script>
