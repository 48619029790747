import { api } from '../utils'

const templateState = {
  template: {},
  templates: [],
}

const mutations = {
  setTemplates(state, templates) {
    state.templates = templates
  },
  setTemplate(state, template) {
    state.template = template
  },
}

const actions = {
  async fetchTemplates({ commit }, { params }) {
    const queryParams = {
      params: {
        ...params,
        per_page: params.perPage,
      },
    }
    const res = await api.client.get('/api/v1/superadmins/templates', queryParams)
    commit('setTemplates', res.data)
  },
  async fetchTemplateDetail({ commit }, templateId) {
    const res = await api.client.get(`/api/v1/superadmins/templates/${templateId}`)
    commit('setTemplate', res.data)
  },
  async createTemplate(_, template) {
    await api.client.post('/api/v1/superadmins/templates', template)
  },
  async updateTemplate(_, { templateId, template }) {
    await api.client.put(`/api/v1/superadmins/templates/${templateId}`, template)
  },
}

export default {
  state: templateState,
  mutations,
  actions,
}
