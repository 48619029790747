import { api } from '../utils'

const actions = {
  async updatePerformance(_, payload) {
    await api.client.post('/api/v1/superadmins/performance', payload)
  },
  async updateUserPerformance(_, payload) {
    await api.client.post('/api/v1/superadmins/user_performance', payload)
  },
}

export default {
  actions,
}
