import Vue from 'vue'
import Router from 'vue-router'
import store from './stores'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'menu',
      component: () => import('./pages/Menu.vue'),
    },
    {
      path: '/attachment',
      name: 'attachment',
      component: () => import('./pages/BPOAttachment.vue'),
    },
    {
      path: '/create_bpo',
      name: 'create_bpo',
      component: () => import('./pages/BPOCreator.vue'),
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('./pages/Users.vue'),
    },
    {
      path: '/clients',
      name: 'clients',
      component: () => import('./pages/Clients.vue'),
    },
    {
      path: '/users/:userId',
      name: 'userDetail',
      component: () => import('./pages/UserDetail.vue'),
    },
    {
      path: '/create_manager',
      name: 'create_manager',
      component: () => import('./pages/CreateManager.vue'),
    },
    {
      path: '/login',
      name: 'authentication',
      component: () => import('./pages/Login.vue'),
      meta: { noAuth: true },
    },
    {
      path: '/login/oauthcallback',
      name: 'authentication_callback',
      component: () => import('./pages/OpenIDCallback.vue'),
      meta: { noAuth: true },
    },
    {
      path: '/announcements',
      name: 'announcements',
      component: () => import('./pages/Announcements.vue'),
    },
    {
      path: '/templates',
      name: 'templates',
      component: () => import('./pages/Templates.vue'),
    },
    {
      path: '/templates/:templateId',
      name: 'templateDetail',
      component: () => import('./pages/TemplateDetail.vue'),
    },

    {
      path: '/templates/:templateId/edit',
      name: 'templateEdit',
      component: () => import('./pages/TemplateEdit.vue'),
    },

    {
      path: '/create_template',
      name: 'templateCreate',
      component: () => import('./pages/TemplateCreate.vue'),
    },

    {
      path: '/signup_requests',
      name: 'signupRequests',
      component: () => import('./pages/SignupRequests.vue'),
    },

    {
      path: '/performance',
      name: 'updatePerformance',
      component: () => import('./pages/Performance.vue'),
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.noAuth) {
    return next()
  }

  // set vuex state
  if (!store.state.auth.loginUser) {
    try {
      await store.dispatch('auth/getCurrentUser')
    } catch (err) {
      console.error(err)
      next({ path: '/login' })
    }
  }

  // check session_id
  const session = localStorage.getItem('session_id')
  if (!session) {
    return next({ path: '/login' })
  }
  return next()
})

export default router
