import Vue from 'vue'
import Vuex from 'vuex'
import error from './error'
import client from './client'
import bpo from './bpo'
import user from './user'
import attachment from './attachment'
import template from './template'
import performance from './performance'
import signupRequest from './signup_request'
import auth from './auth'

Vue.use(Vuex)

export default new Vuex.Store({
  namespace: false,
  modules: {
    client,
    bpo,
    user,
    attachment,
    template,
    performance,
    signupRequest,
    auth,
    error,
  },
})
