<template>
  <v-toolbar color="teal">
    <v-toolbar-title>
      <router-link :to="{ name: 'menu' }" class="white--text" style="text-decoration: none;">
        {{ $t('title') }}
      </router-link>
    </v-toolbar-title>
    <v-spacer />
    <language-switcher />
    <div v-if="loginUser" class="white--text">
      <span>{{ loginUser.email }}</span>
    </div>
    <v-menu v-if="loginUser" bottom left>
      <template v-slot:activator="{ on }">
        <v-btn dark icon v-on="on">
          <v-icon>more_vert</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item-group>
          <router-link class="router-link" :to="{ name: 'create_bpo' }">
            <v-list-item>
              <v-list-item-title>New Unit</v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link class="router-link" :to="{ name: 'create_manager' }">
            <v-list-item>
              <v-list-item-title>New Annotation Manager</v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link class="router-link" :to="{ name: 'attachment' }">
            <v-list-item>
              <v-list-item-title>BPO Attachment</v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link class="router-link" :to="{ name: 'users' }">
            <v-list-item>
              <v-list-item-title>{{ $t('users.user_management') }}</v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link class="router-link" :to="{ path: 'announcements' }">
            <v-list-item>
              <v-list-item-title>Announcements</v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link class="router-link" :to="{ path: 'clients' }">
            <v-list-item>
              <v-list-item-title>Clients</v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link class="router-link" :to="{ path: 'templates' }">
            <v-list-item>
              <v-list-item-title>Templates</v-list-item-title>
            </v-list-item>
          </router-link>
          <v-divider />

          <a class="router-link" @click="onLogout">
            <v-list-item>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </a>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </v-toolbar>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import LanguageSwitcher from './LanguageSwitcher.vue'

export default {
  components: {
    LanguageSwitcher,
  },
  computed: {
    ...mapState('auth', ['loginUser']),
  },
  methods: {
    ...mapActions('auth', ['logout']),
    onLogout() {
      this.logout().then(() => {
        this.$router.push('/login')
      })
    },
  },
}
</script>

<style>
.router-link {
  color: teal;
  text-decoration: none;
}
</style>
