import Vue from 'vue'
import VueI18n from 'vue-i18n'

import jaLocale from './locales/ja.json'
import enLocale from './locales/en.json'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: window.localStorage.getItem('currentLanguage') || 'ja',
  fallbackLocale: 'ja',
  messages: {
    ja: jaLocale,
    en: enLocale,
  },
})

export default i18n
