import Vue from 'vue'
import { firestorePlugin } from 'vuefire'
import Snotify, { SnotifyPosition } from 'vue-snotify'
import TreeView from 'vue-json-tree-view'
import vuetify from '@/plugins/vuetify'
import router from './router'
import store from './stores'
import App from './App.vue'
import './filters'
import { auth } from './plugins/firebase'
import { api } from './utils'

import i18n from './i18n'

Vue.config.productionTip = false
Vue.use(firestorePlugin)
Vue.use(TreeView)

const options = {
  toast: {
    position: SnotifyPosition.rightTop,
    showProgressBar: false,
    preventDuplicates: true,
  },
}

Vue.use(Snotify, options)

api.onError((err) => {
  store.dispatch('error', err)
})
/* eslint-disable no-new */
const unsubscribe = auth.onAuthStateChanged(() => {
  new Vue({
    i18n,
    vuetify,
    router,
    store,
    beforeCreate() {
      Vue.$snotify = this.$snotify
    },
    render: (h) => h(App),
  }).$mount('#app')
  unsubscribe()
})
